import { createAction } from '@ngrx/store';

export const USER_ACTION_LOGOUT = '[APP] Logout';
export const LOGOUT_ACTION = createAction('[App] Logout');

export function logoutReducer(reducer: any) {
  return function (state: any, action: any) {
    if (action.type === USER_ACTION_LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}
