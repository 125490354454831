import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { priorityLeadsActions, PriorityLeadsActionTypes } from '../../actions/priority-leads.actions';
import { TasksServiceGateway } from '../../../../service-gateways/tasks/tasks.service-gateway';
import { EMPTY, catchError, filter, first, map, retry, switchMap, tap, timer } from 'rxjs';
import { PrioritizedLeadRecord, PriorityLeadRecordType } from 'common.interfaces';
import {
  selectTopPriorityMuteState,
  selectPriorityLeads,
  selectTopPriorityActiveState,
} from '../../selectors/priority-leads.selectors';
import { createLeadNotification } from './priority-leads.effects.utils';
import { NotificationService } from '@origin8-web/core-ui/notification';
import { SnackMessageService } from '@origin8-web/core-ui/snack';
import { UserStore } from '../../user/user.store';
import { toObservable } from '@angular/core/rxjs-interop';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class PriorityLeadsEffects {
  private readonly userStore = inject(UserStore);
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly tasksServiceGateway = inject(TasksServiceGateway);
  private readonly notificationService = inject(NotificationService);
  private readonly snackMessageService = inject(SnackMessageService);

  FIRST_NOTIFICATION_THRESHOLD_IN_MIN = 10;
  SECOND_NOTIFICATION_THRESHOLD_IN_MIN = 5;
  NOTIFICATION_CHECK_RATE_IN_MS = 60 * 1000;
  GET_LEADS_REFRESH_RATE_IN_MS = 60 * 1000;
  TEN_SECONDS = 10000; /* in ms */

  userInfo$ = toObservable(this.userStore.userInfo);
  getPrioritizedLeads$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PriorityLeadsActionTypes.SET_ACTIVE_STATE),
        switchMap(() => {
          return this.store.pipe(select(selectTopPriorityActiveState));
        }),
        switchMap((isActive) => {
          return isActive ? this.userInfo$ : EMPTY;
        }),
        filter((user) => {
          return !!user?.email;
        }),
        tap(() => {
          this.store.dispatch(priorityLeadsActions.setIsLoadingPrioritizedLeads({ loadingPrioritizedLeads: true }));
        }),
        switchMap((user) => {
          return timer(1, this.GET_LEADS_REFRESH_RATE_IN_MS).pipe(map(() => user));
        }),
        switchMap((user) =>
          this.tasksServiceGateway.getPrioritizedLeads(user?.email as string).pipe(
            retry({ count: 2, delay: this.TEN_SECONDS }),
            catchError((e) => {
              console.error(`Could not fetch 08 60min leads`, e);
              this.snackMessageService.showError(`An error occured while fetching the O8 60min leads.`);
              this.store.dispatch(
                priorityLeadsActions.setIsLoadingPrioritizedLeads({ loadingPrioritizedLeads: false }),
              );
              return EMPTY;
            }),
          ),
        ),
        map((data: PrioritizedLeadRecord[]) =>
          data
            .filter((record) => record.recordType === PriorityLeadRecordType.LEAD)
            .map((record) => record.recordDetails),
        ),
        tap((data) => {
          this.store.dispatch(priorityLeadsActions.setPrioritizedLeads({ leadsDetails: data }));
          this.store.dispatch(priorityLeadsActions.setIsLoadingPrioritizedLeads({ loadingPrioritizedLeads: false }));
        }),
      );
    },
    { dispatch: false },
  );

  sendNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PriorityLeadsActionTypes.SET_MUTE_STATE, PriorityLeadsActionTypes.SET_ACTIVE_STATE),
        concatLatestFrom(() => [
          this.store.pipe(select(selectTopPriorityActiveState)),
          this.store.pipe(select(selectTopPriorityMuteState)),
        ]),
        switchMap(([, active, muted]) => {
          if (muted || !active) {
            return EMPTY;
          }
          return timer(1, this.NOTIFICATION_CHECK_RATE_IN_MS).pipe(
            switchMap(() => this.store.pipe(select(selectPriorityLeads), first())),
            map((leads) =>
              createLeadNotification(
                this.tasksServiceGateway,
                leads,
                this.NOTIFICATION_CHECK_RATE_IN_MS,
                this.FIRST_NOTIFICATION_THRESHOLD_IN_MIN,
                this.SECOND_NOTIFICATION_THRESHOLD_IN_MIN,
              ),
            ),
            switchMap(({ title, options }) =>
              title ? this.notificationService.displayNotification(title, options) : EMPTY,
            ),
            catchError(() => {
              return EMPTY;
            }),
          );
        }),
      );
    },
    { dispatch: false },
  );
}
