import { OnClickNotificationFn } from '@origin8-web/core-ui/notification';
import { TasksServiceGateway } from '../../../../service-gateways/tasks/tasks.service-gateway';
import { PrioritizedLeadCalculated } from 'common.interfaces';
import { DateTime, Duration } from 'luxon';
import { convertProductName, calculatePriorityLeadsTimes } from '../../../../utils/utils';

interface LeadNotification {
  title?: string;
  options: NotificationOptions & { onClick?: OnClickNotificationFn };
}

interface LeadsAndNotificationData {
  leadsToNotify: PrioritizedLeadCalculated[];
  notification: LeadNotification;
}

export const getTopPriorityWorkSingleNotification = (
  lead: PrioritizedLeadCalculated,
  service: TasksServiceGateway,
): LeadNotification => {
  let numberOfMinutesLeftText;
  if (lead.remainingTime?.minutes !== undefined) {
    numberOfMinutesLeftText = `${lead.remainingTime?.minutes}`;
  }
  const title = `[Protec8] ${numberOfMinutesLeftText} min left on 1 Lead!`;
  const body = `${lead.carrierFromAd} ${convertProductName(lead.productFromAd)} in ${lead.stateFromAd}: ${lead.name}`;
  const options = {
    body,
    data: {
      lead,
    },
    onClick: ({ lead }: { lead: PrioritizedLeadCalculated }) => {
      service.openTaskInSf(lead.externalIdentifier);
    },
  };
  return { title, options };
};
export const getTopPriorityWorkGroupNotification = (
  threshold: number,
  leadsToNotify: PrioritizedLeadCalculated[],
): LeadNotification => {
  const formatter = new Intl.ListFormat('en-US', { style: 'short', type: 'conjunction' });
  const title = `[Protec8] ${threshold} min left on ${leadsToNotify.length} Leads!`;
  const distinctLeadProducts = getUniqueValues(leadsToNotify.map((lead) => convertProductName(lead.productFromAd)));
  const distinctLeadStates = getUniqueValues(leadsToNotify.map((lead) => lead.stateFromAd));
  const body = `${formatter.format(distinctLeadProducts)} leads in ${formatter.format(distinctLeadStates)}`;
  const options = {
    body,
  };
  return { title, options };
};

export const calculateLeadsToNotifyAbout = (
  leads: PrioritizedLeadCalculated[],
  thresholdTime: number,
  interval: number,
): PrioritizedLeadCalculated[] => {
  const _SECONDS_IN_1MINUTE = 60;

  return calculatePriorityLeadsTimes(leads).filter((record) => {
    return (
      record.remainingTime &&
      Duration.fromObject(record.remainingTime).toMillis() <= thresholdTime * 1000 * _SECONDS_IN_1MINUTE + interval &&
      Duration.fromObject(record.remainingTime).toMillis() >= thresholdTime * 1000 * _SECONDS_IN_1MINUTE
    );
  });
};

export const convertToSingleNotification = (
  FIRST_NOTIFICATION_THRESHOLD_IN_MIN: number,
  SECOND_NOTIFICATION_THRESHOLD_IN_MIN: number,
  [firstNotificationData, secondNotificationData]: [LeadsAndNotificationData | null, LeadsAndNotificationData | null],
): LeadNotification => {
  let title, body, options;
  if (firstNotificationData && secondNotificationData) {
    title = `[Protec8] 5-10 min left on ${
      firstNotificationData.leadsToNotify.length + secondNotificationData.leadsToNotify.length
    } Leads!`;
    body =
      [SECOND_NOTIFICATION_THRESHOLD_IN_MIN, ' min left > ', secondNotificationData.notification.options.body].join(
        '',
      ) +
      '\n' +
      [FIRST_NOTIFICATION_THRESHOLD_IN_MIN, ' min left > ', firstNotificationData.notification.options.body].join('');
    options = { body };
  } else if (firstNotificationData && !secondNotificationData) {
    title = firstNotificationData.notification.title;
    options = firstNotificationData.notification.options;
  } else if (!firstNotificationData && secondNotificationData) {
    title = secondNotificationData.notification.title;
    options = secondNotificationData.notification.options;
  }
  const tag = `protec8-top-priority-work-${DateTime.now().toUnixInteger()}`;
  return { title, options: { ...options, tag } };
};

export const createLeadNotification = (
  tasksServiceGateway: TasksServiceGateway,
  leads: PrioritizedLeadCalculated[],
  NOTIFICATION_CHECK_RATE_IN_MS: number,
  FIRST_NOTIFICATION_THRESHOLD_IN_MIN: number,
  SECOND_NOTIFICATION_THRESHOLD_IN_MIN: number,
): LeadNotification => {
  const [firstNotificationData, secondNotificationData] = [
    FIRST_NOTIFICATION_THRESHOLD_IN_MIN,
    SECOND_NOTIFICATION_THRESHOLD_IN_MIN,
  ].map((threshold) => {
    const data = {
      leadsToNotify: calculateLeadsToNotifyAbout(leads, threshold, NOTIFICATION_CHECK_RATE_IN_MS),
      threshold,
    };
    if (data.leadsToNotify.length) {
      if (data.leadsToNotify.length === 1) {
        return {
          leadsToNotify: data.leadsToNotify,
          notification: getTopPriorityWorkSingleNotification(data.leadsToNotify[0], tasksServiceGateway),
        };
      } else {
        return {
          leadsToNotify: data.leadsToNotify,
          notification: getTopPriorityWorkGroupNotification(threshold, data.leadsToNotify),
        };
      }
    } else return null;
  });

  return convertToSingleNotification(FIRST_NOTIFICATION_THRESHOLD_IN_MIN, SECOND_NOTIFICATION_THRESHOLD_IN_MIN, [
    firstNotificationData,
    secondNotificationData,
  ]);
};

const getUniqueValues = (array: string[]) =>
  array.filter((currentValue, index, arr) => arr.indexOf(currentValue) === index);
