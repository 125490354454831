import { Routes } from '@angular/router';
import { authRoutes, canActivateAuth } from '@origin8-web/core-ui/login/routes';
import { PROTEC8_PATH, RELEASE_PATH } from '../app.interface';
import { QUOTES_ROUTE } from '../features/quotes/quotes.interface';
import { canActivatePermissions } from '@origin8-web/core-ui/login/providers';
import { APP_PERMS } from 'common.interfaces';

export const appRoutes: Routes = [
  {
    path: PROTEC8_PATH.WARM_TRANSFER,
    canActivate: [canActivateAuth],
    loadComponent: () =>
      import('../features/warm-transfer/wt-page/warm-transfer-page.component').then((m) => m.WarmTransferPageComponent),
  },
  {
    path: PROTEC8_PATH.INCOMING_WARM_TRANSFER,
    canActivate: [canActivateAuth],
    loadComponent: () =>
      import('../features/warm-transfer/popup/incoming-wt-popup.component').then((m) => m.IncomingWtPopupComponent),
  },
  {
    path: QUOTES_ROUTE.BASE,
    canActivate: [canActivateAuth],
    loadChildren: () => import('../features/quotes/quotes.module').then((m) => m.QuotesModule),
  },
  ...authRoutes,
  {
    path: PROTEC8_PATH.DASHBOARD,
    pathMatch: 'full',
    canActivate: [canActivateAuth],

    loadComponent: () => import('../features/dashboard/dashboard-page.component').then((m) => m.DashboardPageComponent),
  },
  {
    path: PROTEC8_PATH.ADVOCATE_SCHEDULE_ADHERENCE,
    pathMatch: 'full',
    canActivate: [
      canActivateAuth,
      canActivatePermissions([APP_PERMS.PIPELINE_READ_ALL, APP_PERMS.ADVOCATE_PAID_TIME_READ_ALL]),
    ],
    loadComponent: () =>
      import('../features/advocate-schedule-adherence/advocate-schedule-adherence.component').then(
        (m) => m.AdvocateScheduleAdherenceComponent,
      ),
  },
  {
    path: PROTEC8_PATH.SCORECARD,
    pathMatch: 'full',
    canActivate: [canActivateAuth, canActivatePermissions([APP_PERMS.FINANCIAL_INFO_READ_ALL])],
    loadComponent: () => import('../features/scorecard/scorecard.component').then((m) => m.ScorecardComponent),
  },
  {
    path: PROTEC8_PATH.CALL_SCRIPT_EDIT,
    pathMatch: 'full',
    canActivate: [canActivateAuth, canActivatePermissions([APP_PERMS.CALL_SCRIPT_WRITE_ALL])],
    loadComponent: () =>
      import('../features/call-scripts/edit/call-script-edit.component').then((m) => m.CallScriptEditComponent),
  },
  {
    path: PROTEC8_PATH.CALL_SCRIPT_VIEW,
    pathMatch: 'full',
    canActivate: [canActivateAuth],
    loadComponent: () =>
      import('../features/call-scripts/view/call-script.view.component').then((m) => m.CallScriptViewComponent),
  },
  {
    path: PROTEC8_PATH.CALL_SCRIPT_ALL,
    pathMatch: 'full',
    canActivate: [canActivateAuth],
    loadComponent: () =>
      import('../features/call-scripts/all/call-scripts.all.component').then((m) => m.CallScriptsAllComponent),
  },
  {
    path: PROTEC8_PATH.CALL_REVIEW_LIST,
    pathMatch: 'full',
    canActivate: [canActivateAuth, canActivatePermissions([APP_PERMS.CALL_REVIEW_READ])],
    loadComponent: () =>
      import('../features/call-reviewing/call-review-listing.component').then((m) => m.CallReviewListingComponent),
  },
  {
    path: PROTEC8_PATH.UNAUTHORIZED,
    loadComponent: () => import('../features/unauthorized/unauthorized.component').then((m) => m.UnauthorizedComponent),
  },
  {
    path: PROTEC8_PATH.RELEASES,
    pathMatch: 'full',
    canActivate: [canActivateAuth],
    loadComponent: () => import('../features/releases/release-index.page').then((m) => m.ReleaseIndexPageComponent),
  },
  {
    path: RELEASE_PATH.NOTES_2024_03_11,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-03-11/release-notes-2024-03-11-page').then(
        (m) => m.ReleaseNotes20240311Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_04_03,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-04-03/release-notes-2024-04-03-page').then(
        (m) => m.ReleaseNotes20240403Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_04_22,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-04-22/release-notes-2024-04-22-page').then(
        (m) => m.ReleaseNotes20240416Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_05_14,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-05-14/release-notes-2024-05-14-page').then(
        (m) => m.ReleaseNotes20240514Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_06_04,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-06-04/release-notes-2024-06-04-page').then(
        (m) => m.ReleaseNotes20240604Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_06_13,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-06-13/release-notes-2024-06-13-page').then(
        (m) => m.ReleaseNotes20240613Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_07_22,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-07-22/release-notes-2024-07-22-page').then(
        (m) => m.ReleaseNotes20240722Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_08_21,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-08-21/release-notes-2024-08-21-page').then(
        (m) => m.ReleaseNotes20240821Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_08_28,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-08-28/release-notes-2024-08-28-page').then(
        (m) => m.ReleaseNotes20240828Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_09_12,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-09-12/release-notes-2024-09-12-page').then(
        (m) => m.ReleaseNotes20240912Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_09_23,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-09-23/release-notes-2024-09-23-page').then(
        (m) => m.ReleaseNotes20240923Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_11_27,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-11-27/release-notes-2024-11-27-page').then(
        (m) => m.ReleaseNotes20241127Component,
      ),
  },
  {
    path: RELEASE_PATH.NOTES_2024_12_09,
    pathMatch: 'full',
    loadComponent: () =>
      import('../features/releases/release-notes-pages/2024-12-09/release-notes-2024-12-09-page').then(
        (m) => m.ReleaseNotes20241209Component,
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
