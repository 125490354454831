import { first, of, switchMap } from 'rxjs';
import { PLATFORM_ID, inject } from '@angular/core';
import { UserService } from '../providers/user.service';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AUTH_PATH } from '../login.interface';
import { isPlatformServer } from '@angular/common';

export const canActivateAuth: CanActivateFn = (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const platformId = inject(PLATFORM_ID);
  if (isPlatformServer(platformId)) {
    return of(false);
  }
  const userService = inject(UserService);
  const router = inject(Router);

  return userService.getIsLoggedIn$().pipe(
    first(),
    switchMap((isLoggedIn) => {
      if (isLoggedIn) {
        return of(true);
      }
      const urlTree = router.parseUrl(`${AUTH_PATH.LOGIN_FULL_PATH}?redirect=${state.url}`);
      return of(urlTree);
    }),
  );
};
