<div class="navbar-menu-dropdown">
  <div class="menu-container">
    <div
      class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100"
      [routerLink]="links.dashboard"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="text-green-500 font-medium"
    >
      Home
    </div>
    @if (!userStore.isContactor()) {
      <div
        class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
        (click)="openWtPage()"
      >
        Open WT Popup
      </div>
      <div
        class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
        [routerLink]="links.warmTransfer"
        routerLinkActive="text-green-500 font-medium"
      >
        Warm Transfer
      </div>
    }
    <div
      class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
      [routerLink]="links.quotes"
      routerLinkActive="text-green-500 font-medium"
    >
      Quotes
    </div>
    <div
      class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
      [routerLink]="links.releases"
      routerLinkActive="text-green-500 font-medium"
    >
      Releases
    </div>
    <div
      class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
      [routerLink]="links.impersonation"
      routerLinkActive="text-green-500 font-medium"
      *o8OnlyPermissions="impersonationPerms"
    >
      Impersonation
    </div>
    <div
      class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
      [routerLink]="links.advocateScheduleAdherence"
      routerLinkActive="text-green-500 font-medium"
      *o8OnlyPermissions="advocateScheduleAdherencePerms"
    >
      Advocate Schedule Adherence
    </div>
    <div
      class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
      [routerLink]="links.allCallScripts"
      routerLinkActive="text-green-500 font-medium"
      *o8OnlyPermissions="callScriptPerms"
    >
      Call Scripts
    </div>
    <div
      class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
      [routerLink]="links.scorecard"
      routerLinkActive="text-green-500 font-medium"
      *o8OnlyPermissions="scorecardPerms"
    >
      Scorecard
    </div>
    @if (!userStore.isRepresentative()) {
      <div
        class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
        [routerLink]="links.callReviewsSummary"
        routerLinkActive="text-green-500 font-medium"
      >
        Call Reviews Summary
      </div>
    }
    @if (userStore.isRepresentative()) {
      <div
        class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
        [routerLink]="links.callReviewsSummary"
        routerLinkActive="text-green-500 font-medium"
      >
        My Call Reviews
      </div>
    }
    <div
      class="text-sm text-black-500 font-semibold py-2 px-4 cursor-pointer hover:bg-gray-100 border-t border-green-500"
      [routerLink]="links.logout"
      routerLinkActive="text-green-500 font-medium"
    >
      Logout
    </div>
  </div>
</div>
